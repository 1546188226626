@import "variables";

// Browser Size Dependent Visibility
.hide-on-small-only, .hide-on-small-and-down {
  @media #{$small-and-down} {
    display: none !important;
  }
}

.hide-on-med-and-down {
  @media #{$medium-and-down} {
    display: none !important;
  }
}

.hide-on-med-and-up {
  @media #{$medium-and-up} {
    display: none !important;
  }
}

.hide-on-med-only {
  @media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
    display: none !important;
  }
}

.hide-on-large-only {
  @media #{$large-and-up} {
    display: none !important;
  }
}

.hide {
  display: none !important;
}

// Converts a unitless, pixel, or rem value to em, for use in breakpoints.
@function -zf-bp-to-em($value) {
  // Pixel and unitless values are converted to rems
  @if unit($value) == 'px' or unitless($value) {
    $value: rem-calc($value, $base-value: 16px);
  }

  // Then the value is converted to ems
  @return strip-unit($value) * 1em;
}

/// Convers the breakpoints map to a URL-encoded string, like this: `key1=value1&key2=value2`. The value is then dropped into the CSS for a special `<meta>` tag, which is read by the Foundation JavaScript. This is how we transfer values from Sass to JavaScript, so they can be defined in one place.
/// @access private
///
/// @param {Map} $map - Map to convert.
///
/// @returns {String} A string containing the map's contents.
@function -zf-bp-serialize($map) {
  $str: '';
  @each $key, $value in $map {
    $str: $str + $key + '=' + -zf-bp-to-em($value) + '&';
  }
  $str: str-slice($str, 1, -2);

  @return $str;
}

// These styles are applied to a <meta> tag, which is read by the Foundation JavaScript
.cmonsite-mq {
  font-family: '#{-zf-bp-serialize($breakpoints)}';
}

// These styles are applied to a <meta> tag, which is read by the Foundation JavaScript
.cmonsite-mq-down {
  font-family: '#{-zf-bp-serialize($breakpoints-down)}';
}


@import "components/components";