.price-list {


  &__items {
    display: block;
    margin: 10px 0;
    padding: 0;
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
  }

  &__name {
    flex: 0 1 auto;

    padding-top: 0;
    padding-bottom: 0;
    margin: 0;

  }

  &__price {
    flex: 0 1 auto;

    margin: 0;
    padding: 0;
  }

  &__deco-center {
    flex: 1 1 auto;
  }

  &__description {
    flex: 0 1 auto;
    width: 100%;

    margin: 0;
    padding: 0;

    font-style: italic;
    font-size: .9em;
    line-height: 1.5em;
    border-top: 1px dotted;

    &:after {
      content: '\0020';
    }
  }

  &--no-description {
    .price-list__description {
      overflow: hidden;
      height: 0;
    }
  }

  &--design-1 {
  }

  &--design-2 {
    .price-list__name, .price-list__price {
      line-height: 14px;
      margin-bottom: 3px;
    }
    .price-list__description {
      border-top: none;
    }
    .price-list__deco-center {
      border-bottom: 1px solid;
      margin: 0 2px;
    }
  }
  &--design-3 {
    .price-list__name, .price-list__price {
      line-height: 14px;
      margin-bottom: 3px;
    }
    .price-list__description {
      border-top: none;
    }
    .price-list__deco-center {
      border-bottom: 1px dotted;
      margin: 0 2px;
    }
  }
  &--design-4 {
    .price-list__name, .price-list__price {
      line-height: 14px;
      margin-bottom: 3px;
    }
    .price-list__description {
      border-top: none;
    }
    .price-list__deco-center {
      border-bottom: 1px dashed;
      margin: 0 2px;
    }
  }

}

// HACK POUR FORCER CERTAINS THEME
ul.price-list__items {
  margin: 10px 0;
  padding: 0;
}