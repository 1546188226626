.widget-horizontal-rules {
  hr {
    margin: 15px 0;
    padding: 0;
    border-left: none;
    border-right: none;
    border-bottom: none;
    height: auto;
    line-height: 0;
  }
  svg {
    display: block;
    margin: 5px 0;
  }
}

hr {

  &, &.style-1, .widget.style-1 & {
    border-top: 1px solid #8c8b8b;
    border-bottom: none;
  }

  .widget-horizontal-rules--design-2 &, .widget.style-2 & {
    border-top: 3px double #8c8b8b;
    border-bottom: none;
  }

  .widget-horizontal-rules--design-3 &, .widget.style-3 & {
    border-top: 1px dashed #8c8b8b;
    border-bottom: none;
  }

  .widget-horizontal-rules--design-4 &, .widget.style-4 & {
    border-top: 1px dotted #8c8b8b;
    border-bottom: none;
  }

  .widget-horizontal-rules--design-5 &, .widget.style-5 & {
    border-top: 2px dashed #8c8b8b;
    border-bottom: none;
  }

  .widget-horizontal-rules--design-6 &, .widget.style-6 & {
    border-top: 2px dotted #8c8b8b;
    border-bottom: none;
  }

  .widget-horizontal-rules--design-7 &, .widget.style-7 & {
    border-top: 1px dashed #8c8b8b;
    border-bottom: none;
  }

  .widget-horizontal-rules--design-8 &, .widget.style-8 & {
    border-top: 1px dotted #8c8b8b;
    border-bottom: none;
  }

  .widget-horizontal-rules--design-svg-1 & {
    height: 6px;
    background: url(/images/cmonsite-editor/hr-1.png) repeat-x 0 0;
    border: 0;
  }
  .widget.style-svg-1 & {
    height: 0;
    margin: 0;
    border: none;
    display: none;
  }

  .widget-horizontal-rules--design-svg-2 & {
    height: 6px;
    background: url(/images/cmonsite-editor/hr-2.png) repeat-x 0 0;
    border: 0;
  }
  .widget.style-svg-2 & {
    height: 0;
    margin: 0;
    border: none;
    display: none;
  }

  .widget-horizontal-rules--design-svg-3 & {
    height: 13px;
    background: url(/images/cmonsite-editor/hr-3.svg) repeat-x 0 0;
    border: 0;
  }
  .widget.style-svg-3 & {
    height: 0;
    margin: 0;
    border: none;
  }

  .widget-horizontal-rules--design-background-1 &, .widget.style-background-1 & {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #8c8b8b, rgba(255, 255, 255, 0));
  }

}

