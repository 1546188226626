.cocoen {
  box-sizing: border-box;
  cursor: pointer;
  line-height: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.cocoen * {
  box-sizing: inherit
}

.cocoen ::after, .cocoen ::before {
  box-sizing: inherit
}

.cocoen img, .cocoen picture > img {
  max-width: none
}

.cocoen > img, .cocoen > picture > img {
  display: block;
  width: 100%
}

.cocoen > div:first-child, picture .cocoen > div {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 50%
}

.cocoen-drag {
  bottom: 0;
  cursor: ew-resize;
  left: 50%;
  margin-left: -15px;
  position: absolute;
  top: 0;
  width: 30px;
}

.cocoen-drag::before {
  width: 2px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: 0 auto;
  background: #fff;
  content:'';
}

.cocoen-drag::after {
  border-top: 0px solid #fff;
  content: '';
  height: 54px;
  left: 50%;
  margin-left: -27px;
  margin-top: -19px;
  position: absolute;
  top: 50%;
  width: 53px;
  background-image: url('/images/theme/swipe.svg');
  background-repeat: no-repeat;
  background-size: cover;
}


.preview-after-before {
  max-width: 380px;
  margin: 0 auto 30px
}

.cocoen-drag::before {
  width: 2px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: 0 auto;
  background: #fff;
  content: ''
}

.cocoen-drag::after {
  border-top: 0px solid #fff;
  content: '';
  height: 54px;
  left: 50%;
  margin-left: -27px;
  margin-top: -19px;
  position: absolute;
  top: 50%;
  width: 53px;
  background-image: url("/images/avant-apres/swipe.svg");
  background-repeat: no-repeat;
  background-size: cover;
  animation-duration: 3s;
  animation-name: respire;
  animation-iteration-count: infinite
}



@keyframes respire {
  from {
    -webkit-transform: translate(-10%, 0);
    transform: translate(-10%, 0)
  }
  50% {
    -webkit-transform: translate(10%, 0);
    transform: translate(10%, 0)
  }
  to {
    -webkit-transform: translate(-10%, 0);
    transform: translate(-10%, 0)
  }
}