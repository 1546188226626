.widget-image {
  img {
    max-width: 100%;
    box-sizing: border-box;
  }

  figcaption {
    padding: 6px 10px 5px;
    font-style: italic;
    text-align: center;
    &:empty {
      display: none;
    }
  }

  figure {
    margin: 0;
    max-width: 100%;

    &.top {

    }

    &.bottom {

    }

    &.hover {
      figcaption {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }

      &:hover, &:focus {
        figcaption {
          display: block;
        }
      }
    }
  }

  &.style-circle img {
    border-radius: 50%;
  }

  &.style-ronde img {
    border-radius: 25px;
  }

  &.style-semi-ronde img {
    border-top-left-radius: 2em;
    border-bottom-right-radius: 2em;
  }

  &.style-bulle img {
    border-top-left-radius: 10em;
    border-bottom-right-radius: 10em;
    border-bottom-left-radius: 10em;
  }

  &.style-photo-imprime figure {
    padding: 5px;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
    display: inline-block;
    //box-sizing: border-box;
  }

  &.style-polaroid {
    figure {
      margin-bottom: 5px;
      padding: 5px 5px 10px;
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
      display: inline-block;
      &.hover {
        figcaption {
          position: static;
        }
      }
    }

    figcaption {
      padding: 10px 10px 5px;
    }
  }

  &.style-ombre {
    .widget-image-link {
      padding: 20px;
    }
    img {
      box-shadow: 13px 14px 21px 0px rgba(0, 0, 0, 0.3);

    }
  }

}

.widget-image-link {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  max-width: 100%;
}


/* Zoom In #1 */
.hover-zoom-in {
  img {
    transform: scale(1);
    transition: transform .3s ease-in-out;
  }
  &:hover img {
    transform: scale(1.3);
  }
}

.hover-zoom-out {
  img {
    transform: scale(1.5);
    transition: transform .3s ease-in-out;
  }
  &:hover img {
    transform: scale(1);
  }
}

.hover-slide {
  img {
    margin-left: 30px;
    transform: scale(1.5);
    transition: transform .3s ease-in-out;
  }
  &:hover img {
    margin-left: 0;
  }
}

.hover-rotate {
  img {
    transform: rotate(15deg) scale(1.4);
    transition: transform .3s ease-in-out;
  }
  &:hover img {
    transform: rotate(0deg) scale(1);
  }
}

.hover-blur {
  img {
    filter: blur(3px);
    transition: filter .3s ease-in-out;
  }
  &:hover img {
    filter: blur(0);
  }
}

.hover-gray-scale {
  img {
    filter: grayscale(100%);
    transition: filter .3s ease-in-out;
  }
  &:hover img {
    filter: grayscale(0);
  }
}

.hover-opacity {
  img {
    opacity: 1;
    transition: opacity .3s ease-in-out;
  }
  &:hover img {
    opacity: .5;
  }
}

.hover-blur-grayscale {
  img {
    filter: grayscale(0) blur(0);
    transition: filter .3s ease-in-out;
  }
  &:hover img {
    filter: grayscale(100%) blur(3px);
  }
}

.hover-flashing {
  &:hover img {
    opacity: 1;
    animation: flash 1.5s;
  }
}

@-webkit-keyframes flash {
  0% {
    opacity: .4;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: .4;
  }
  100% {
    opacity: 1;
  }
}

.hover-shine {
  figure {
    position: relative;
    overflow: hidden;
  }
  figure:before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    transform: skewX(-25deg);
  }
  &:hover figure:before {
    animation: shine .75s;
  }
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.hover-circle {

  figure {
    position: relative;
    overflow: hidden;
  }

  figure:before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, .2);
    border-radius: 100%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }
  &:hover figure:before {
    animation: circle .75s;
  }

}

@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}



