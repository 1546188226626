.banner_diaporama_carouselticker {

  margin:0 auto;
  background:transparent;
  max-width:100%;
  overflow:hidden;

  > ul, .carouselTicker__wrap > ul {
    padding: 0;
    margin: 0;
    width: 100000px;
    li {
      float: left;
      width: auto;
      position: relative;
      margin: 0 10px 0 0;
      list-style-type: none;
    }
    &::after{
      content:'';
      clear: both;
      display: block;
    }
  }


  img {
    width: auto;
    display:inline-block;
  }
}

@media #{$medium-and-down}{
  .banner_diaporama_carouselticker img {
    max-height:300px;
    height: auto;
    max-width:100%;
    width:auto;
  }
}

@media #{$small-and-down}{
  .accueil_diaporama_template_6{

    img{
      max-height: 200px;
      min-height: 200px;
    }

  }
}


