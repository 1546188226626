.instagram-feed.instagram-feed-1 {

  margin-left: -15px;
  margin-right: -15px;

  .instagram-feed__item {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 28px;

  }
}

