.formError {
  position: absolute;
  top: 300px;
  left: 300px;
  width: 150px;
  padding-bottom: 15px;
  display: block;
  z-index: 5000;
}

.formError .formErrorContent {
  width: 100%;
  background: #000;
  color: #fff;
  font-family: tahoma;
  font-size: 10px;
  box-shadow: 0 0 6px #000;
  -moz-box-shadow: 0 0 6px #000;
  -webkit-box-shadow: 0 0 6px #000;
  padding: 4px 10px;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}

.formError .formErrorArrow {
  position: absolute;
  bottom: 0;
  left: 20px;
  width: 15px;
  height: 15px;
}

.formError .formErrorArrow div {
  box-shadow: 0 0 6px #000;
  -moz-box-shadow: 0 0 6px #000;
  -webkit-box-shadow: 0 0 6px #000;
  font-size: 0;
}

.formError .formErrorArrow .line10 {
  width: 15px;
  height: 1px;
  background: #000;
  margin: 0 auto;
  font-size: 0;
  display: block;
}

.formError .formErrorArrow .line9 {
  width: 13px;
  height: 1px;
  background: #000;
  margin: 0 auto;
  display: block;
}

.formError .formErrorArrow .line8 {
  width: 11px;
  height: 1px;
  background: #000;
  margin: 0 auto;
  display: block;
}

.formError .formErrorArrow .line7 {
  width: 9px;
  height: 1px;
  background: #000;
  margin: 0 auto;
  display: block;
}

.formError .formErrorArrow .line6 {
  width: 7px;
  height: 1px;
  background: #000;
  margin: 0 auto;
  display: block;
}

.formError .formErrorArrow .line5 {
  width: 5px;
  height: 1px;
  background: #000;
  margin: 0 auto;
  display: block;
}

.formError .formErrorArrow .line4 {
  width: 3px;
  height: 1px;
  background: #000;
  margin: 0 auto;
  display: block;
}

.formError .formErrorArrow .line3 {
  width: 1px;
  height: 1px;
  background: #000;
  margin: 0 auto;
  display: block;
}

@media (max-width: 1023px) {
  .formError {
    opacity: 1 !important;
    position: static !important;
    top: auto !important;
    left: auto !important;
    margin-top: 0 !important;
    display: block;
    width: auto;
    padding: 0;
  }
  .formError .formErrorArrow {
    display: none;
  }
  .formError .formErrorContent {
    background: none;
    box-shadow: none;
    color: red;
    font-size: 0.8em;
    padding: 0;
    margin: 0;
    height: auto;
  }

  .formError + [class*="validate"] {
    border-color: red;
  }
}