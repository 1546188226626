
.container {
  padding: 0 $gutter-width;
  margin: 0 auto;
  max-width: 1280px;
  width: 90%;
}
@media #{$medium-and-up} {
  .container {
    width: 85%;
  }
}
@media #{$large-and-up} {
  .container {
    width: 70%;
  }
}
.container .row, .theme .row{
  margin-left: (-1 * $gutter-width / 2);
  margin-right: (-1 * $gutter-width / 2);
}
.section {
	padding-top: 3%;
	padding-bottom: 3%;

  &.no-pad {
    padding: 0;
  }
  &.no-pad-bot {
    padding-bottom: 0;
  }
  &.no-pad-top {
    padding-top: 0;
  }
}

.section .row {
	padding-top: $gutter-width;
	padding-bottom: $gutter-width;
}

.row {
  margin-left: -($gutter-width / 2);
  margin-right: -($gutter-width / 2);

  /*
    Mise en place de flexbox, en test
  */
  display: flex;
  flex-wrap: wrap;

  // Clear floating children
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .col {
    //float: left;
    box-sizing: border-box;
    padding: 0 $gutter-width / 2;

    flex: 0 1 auto;
    width: 100%;

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      &.xs#{$i} {
        width: $perc;
        margin-left: 0;
      }
      $i: $i + 1;
    }

    &.xs2-4 {
      width: 20%;
      margin-left: 0;
    }

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      &.offset-xs#{$i} {
        margin-left: $perc;
      }
      $i: $i + 1;
    }
    
    
    @media #{$medium-and-up} {

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.s#{$i} {
          width: $perc;
          margin-left: 0;
        }
        $i: $i + 1;
      }

      &.s2-4 {
        width: 20%;
        margin-left: 0;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.offset-s#{$i} {
          margin-left: $perc;
        }
        $i: $i + 1;
      }

    }

    @media #{$medium-and-up} {

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.m#{$i} { 
          width: $perc; 
          margin-left: 0;
        }
        $i: $i + 1;
      }

      &.m2-4 {
        width: 20%;
        margin-left: 0;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.offset-m#{$i} { 
          margin-left: $perc;
        }
        $i: $i + 1;
      } 

    }

    @media #{$large-and-up} {

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.l#{$i} { 
          width: $perc; 
          margin-left: 0;
        }
        $i: $i + 1;
      }

      &.l2-4 {
        width: 20%;
        margin-left: 0;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.offset-l#{$i} { 
          margin-left: $perc;
        }
        $i: $i + 1;
      } 

    }

  }
}
