@import '../../../swiper';

.widget-instagram-feed {

  .instagram-feed {
    display: flex;
    flex-wrap: wrap;

    &__item {
      width: calc(100% / 3);


      &__container {
        position: relative;
        overflow: hidden;
      }

      &__image {
        display: block;
        overflow: hidden;
        padding-bottom: 100%;
        position: relative;

        img {
          object-fit: cover;
          height: 100% !important;
          left: 0;
          position: absolute;
          top: 0;
          user-select: none;
          width: 100%;
        }
      }

      &__icon {
        position: absolute;
        top: 8px;
        right: 8px;
        font-size: 22px;
        color: #fff;
      }

      &__caption {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
        padding: 20px;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        text-align: center;
        text-overflow: ellipsis;

        .fa-play {
          font-size: 28px;
          margin-top: 10px;
        }
      }

      &:hover .instagram-feed__item__caption {
        display: flex;
        flex-direction: column;
      }

    }
  }


  @import '_template_1';

  @import '_template_2';

  @import '_template_3';

  @import '_template_4';


  @media #{$large-and-up} {


    &[data-column="1"] {
      .instagram-feed-1 .instagram-feed__item, .instagram-feed-2 .instagram-feed__item, .instagram-feed-3 .swiper-slide, .instagram-feed.instagram-feed-4 .swiper-slide {
        width: 100%;
      }

    }
    &[data-column="2"] {
      .instagram-feed-1 .instagram-feed__item, .instagram-feed-2 .instagram-feed__item, .instagram-feed-3 .swiper-slide, .instagram-feed.instagram-feed-4 .swiper-slide {
        width: calc(100% / 2);
      }

    }

    &[data-column="3"] {
      .instagram-feed-1 .instagram-feed__item, .instagram-feed-2 .instagram-feed__item, .instagram-feed-3 .swiper-slide, .instagram-feed.instagram-feed-4 .swiper-slide {
        width: calc(100% / 3);
      }
    }

    &[data-column="4"] {
      .instagram-feed-1 .instagram-feed__item, .instagram-feed-2 .instagram-feed__item, .instagram-feed-3 .swiper-slide, .instagram-feed.instagram-feed-4 .swiper-slide {
        width: calc(100% / 4);
      }
    }

    &[data-column="5"] {

      .instagram-feed-1 .instagram-feed__item, .instagram-feed-2 .instagram-feed__item, .instagram-feed-3 .swiper-slide, .instagram-feed.instagram-feed-4 .swiper-slide {
        width: calc(100% / 5);
      }
    }

    &[data-column="6"] {
      .instagram-feed-1 .instagram-feed__item, .instagram-feed-2 .instagram-feed__item, .instagram-feed-3 .swiper-slide, .instagram-feed.instagram-feed-4 .swiper-slide {
        width: calc(100% / 6);

        &__caption {
          font-size: 14px;
        }
      }
    }

    &[data-column="7"] {

      .instagram-feed-1 .instagram-feed__item, .instagram-feed-2 .instagram-feed__item, .instagram-feed-3 .swiper-slide, .instagram-feed.instagram-feed-4 .swiper-slide {
        width: calc(100% / 7);

        &__caption {
          font-size: 14px;
        }
      }
    }

    &[data-column="8"] {

      .instagram-feed-1 .instagram-feed__item, .instagram-feed-2 .instagram-feed__item, .instagram-feed-3 .swiper-slide, .instagram-feed.instagram-feed-4 .swiper-slide {
        width: calc(100% / 8);

        &__caption {
          font-size: 14px;
        }
      }
    }

  }
}



