
/* 
    Created on : 7 oct. 2014, 15:13:28
    Author     : Damien T. - Lord of Web
*/
 
.message-wrap {
    border: 1px solid #cccccc;
    box-shadow: 0 0 3px #bbb;
    margin-bottom: 10px;
    padding: 0;
}
.message-wrap .msg {
    margin: 0;
    padding: 5px;
}
.message-wrap .msg-wrap { 
    max-height: 400px;
    overflow: auto;
    padding: 10px;
}
.message-wrap .media-body .time {
    color: #bfbfbf;
}
.msg-wrap .media-heading {
    font-weight: 900;
}
.message-wrap {
    font-size: 15px;
}
.message-wrap small {
    font-size: 13px;
}
.msg-wrap .media-heading small {
    color: #003bb3;
    font-size: 11px;
}
.msg-date {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    border-color: -moz-use-text-color -moz-use-text-color #ddd;
    border-image: none;
    border-style: none none solid;
    border-width: medium medium 1px;
    box-shadow: none;
    color: #aaa;
    text-align: center;
}