
$modal-lg: 1020px;
$modal-md: 600px;
$modal-sm: 300px;


.dialog[aria-hidden="true"] {
  display: none;
}

/* -------------------------------------------------------------------------- *\
 * Styling to make the dialog look like a dialog
 * -------------------------------------------------------------------------- */

.dialog-overlay {
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.66);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.dialog-content {
  background-color: rgb(255, 255, 255);
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* -------------------------------------------------------------------------- *\
 * Extra dialog styling to make it shiny
 * -------------------------------------------------------------------------- */

.dialog-content {
  padding: 1em;
  max-width: 90%;
  width: 600px;
  border-radius: 2px;
}

@media screen and (min-width: 700px) {
  .dialog-content {
    padding: 2em;
  }
}

.dialog-overlay {
  background-color: rgba(43, 46, 56, 0.9);
}

.dialog h1 {
  margin: 0;
  font-size: 1.25em;
}

.dialog-close {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  padding: 0;
  background-color: transparent;
  font-weight: bold;
  font-size: 1.25em;
  width: 1.2em;
  height: 1.2em;
  text-align: center;
  cursor: pointer;
  transition: 0.15s;
}

@media screen and (min-width: 700px) {
  .dialog-close {
    top: 0.1em;
    right: 0.1em;
  }
}


// Scale up the modal
@media #{$medium-up} {
  // Automatically set modal's width for larger viewports
  .dialog-content {
    width: $modal-md;
    margin: 30px auto;
  }

  // Modal sizes
  .modal-sm {
    width: $modal-sm;
  }
}

@media #{$large-up} {
  .modal-lg {
    width: $modal-lg;
  }
}