// Mixins
// --------------------------

@mixin fa-icon() {
  display: inline-block;
  font: normal normal normal #{$fa-font-size-base}/#{$fa-line-height-base} FontAwesome; // shortening font declaration
  font-size: inherit; // can't have font-size inherit on line above, so need to override
  text-rendering: auto; // optimizelegibility throws things off #1094
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

@mixin fa-icon-rotate($degrees, $rotation) {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation});
  -webkit-transform: rotate($degrees);
      -ms-transform: rotate($degrees);
          transform: rotate($degrees);
}

@mixin fa-icon-flip($horiz, $vert, $rotation) {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation});
  -webkit-transform: scale($horiz, $vert);
      -ms-transform: scale($horiz, $vert);
          transform: scale($horiz, $vert);
}


@function unicode($str) {
  @return unquote("\"")+unquote(str-insert($str, "\\", 1))+unquote("\"")
}

@mixin char($character-code) {
  // Escaping in Sass is a bit of a mess?
  // https://github.com/sass/sass/issues/659
  @if function-exists(random) {
    @if function-exists(selector-nest) {
      // Sass 3.4
      content: #{'\"\\' + $character-code + "\""};
    } @else {
      // LibSass
      // Escaping:
      // https://github.com/sass/libsass/issues/1271
      //
      // Outputs a unicode entity instead of a code such as "\f102":
      // https://github.com/sass/libsass/issues/1231
      content: "#{'\"\\' + $character-code + "\""}";
    }
  } @else {
    // Sass 3.3
    content: str-slice("\x", 1, 1) + $character-code;
  }
}