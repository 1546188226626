
.widget-actualite {
  .module-actualite {
    margin: 0;
  }
  .ticker-actualite {
    list-style: none;
  }
}


.module-actualite {
  overflow: hidden;
  transform: translateZ(0);
  position: relative;
}


.ticker-actualite__wrapper {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
}

.ticker-actualite {
  display: flex;
  animation: ticker 15s infinite linear;
  overflow: hidden;
  list-style: none;
  padding: 0;
  margin: 0;
}
.ticker-actualite__visually-hidden {
  opacity: 0;
  padding: 0;
  margin: 0;
}

.module-actualite:hover .ticker-actualite {
  animation-play-state: paused;
}

.ticker-actualite li {
  margin-right: 20px;
  white-space: nowrap;
}



@keyframes ticker {
  100% {
    transform: translateX(-100%);
  }
}
