
.categories-thumbnail {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  text-align: center;
  .button {
    font-size: inherit;
  }
}

.categories-thumbnail__item {
  padding: 0 15px;
  margin-bottom: 15px;
  flex: 0 1 auto;
  width: auto;
}

@media #{$large-and-up} {

  .widget-categories-thumbnail[data-column="2"] .categories-thumbnail__item {
    width: calc(100%/2);
  }

  .widget-categories-thumbnail[data-column="3"] .categories-thumbnail__item {
    width: calc(100%/3);
  }

  .widget-categories-thumbnail[data-column="4"] .categories-thumbnail__item {
    width: calc(100%/4);
  }

  .widget-categories-thumbnail[data-column="5"] .categories-thumbnail__item {
    width: calc(100%/5);
  }

  .widget-categories-thumbnail[data-column="6"] .categories-thumbnail__item {
    width: calc(100%/6);
  }

}



.categories-thumbnail__item-link
{
  position: relative;
  display: flex;
  height: 100%;
  img {
    object-fit: contain;
  }
}

.categories-thumbnail__item-position-text {
  text-align: center;
}


.widget-categories-thumbnail[data-position="over"] .categories-thumbnail__item-position-text {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}


.widget-categories-thumbnail[data-position="over"] .category-thumbnail__item--no-image .categories-thumbnail__item-position-text {
  position: static;
}

.widget-categories-thumbnail[data-position="top"] {
  .categories-thumbnail__item-link {
    flex-direction: column;
  }
  .categories-thumbnail__item-position-text {
    order: -1;
  }
  .categories-thumbnail__item-link
  {
    img {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}


.widget-categories-thumbnail[data-position="bottom"] {
  .categories-thumbnail__item-link {
    flex-direction: column;
  }
  .categories-thumbnail__item-position-text {
    order: 1;
  }
  .categories-thumbnail__item-link
  {
    img {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}


.widget-categories-thumbnail[data-decoration="underline"] {
  .categories-thumbnail__item-link {
    text-decoration: underline;
    * {
      text-decoration: underline;
    }
  }
}

.categories-thumbnail__item-text {
  padding: 10px;
  background-color: hsla(0, 0%, 100%, 0.7);
}



