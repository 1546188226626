/*
    Document   : _main_mixin
    Created on : 9 aout 2013, 11:44:12
    Author     : PC1
    Description: Fonction SASS Reutilisable de Cmonsite
*/

/* Default 990px grid sur 10 colonne
*/
$gridColumns: 12 !default;
$gridColumnWidth: 60px !default;
$gridGutterWidth: 20px !default;
$gridRowWidth: ($gridColumns * $gridColumnWidth) + ($gridGutterWidth * ($gridColumns - 1)) !default;

/* Fluid grid
------------------------- */
$fluidGridColumnWidth: percentage($gridColumnWidth/$gridRowWidth) !default;
$fluidGridGutterWidth: percentage($gridGutterWidth/$gridRowWidth) !default;
$baseLineHeight: 20px !default;
$inputHeight: $baseLineHeight + 10px; // base line-height + 8px vertical padding + 2px top/bottom border


/* Mixins clearfix bootstrap */

@mixin clearfix {
    *zoom: 1;
    &:before,
    &:after {
        display: table;
        content: "";
        /* Fixes Opera/contenteditable bug:
        // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952 */
        line-height: 0;
    }
    &:after {
        clear: both;
    }
}

/* Block level inputs */
@mixin input-block-level {
    display: block;
    width: 100%;
    min-height: $inputHeight; /* Make inputs at least the height of their button counterpart (base line-height + padding + border)*/
    box-sizing: border-box; /* Makes inputs behave like true block-level elements */
}

/* IE7 inline-block
----------------*/
@mixin crossbrowser-inline-block() {
    display: -moz-inline-stack;
    *display: inline; /* IE7 inline-block hack */
    *zoom: 1;
}

/* Opacity */
@mixin opacity($opacity) {
    opacity: $opacity / 100;
    filter: alpha(opacity=$opacity);
}
/* Transitions */
@mixin transition($transition...) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}
@mixin transition-delay($transition-delay) {
    -webkit-transition-delay: $transition-delay;
    -moz-transition-delay: $transition-delay;
    -o-transition-delay: $transition-delay;
    transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration) {
    -webkit-transition-duration: $transition-duration;
    -moz-transition-duration: $transition-duration;
    -o-transition-duration: $transition-duration;
    transition-duration: $transition-duration;
}

/*
    Example usage:
    @include animation(10s, 5s, changecolour)
*/
@mixin animation ($delay, $duration, $animation, $count : 1) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-fill-mode: forwards; /* this prevents the animation from restarting! */
    -webkit-animation-iteration-count:$count;

    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-fill-mode: forwards; /* this prevents the animation from restarting! */
    -moz-animation-iteration-count:$count;

    -o-animation-delay: $delay;
    -o-animation-duration: $duration;
    -o-animation-name: $animation;
    -o-animation-fill-mode: forwards; /* this prevents the animation from restarting! */
    -o-animation-iteration-count:$count;

    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-fill-mode: forwards; /* this prevents the animation from restarting! */
    animation-iteration-count:$count;
}
/* keyframes mixin */
@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

/* Mixin Bootstrap pour generer une grille */
@mixin grid-core($gridColumnWidth, $gridGutterWidth) {

    .row {
        margin-left: $gridGutterWidth * -1;
        @include clearfix();
    }


    [class*="span"] {
        float: left;
        min-height: 1px; /* prevent collapsing columns */
        margin-left: $gridGutterWidth;
    }

    /* generate .spanX */
    @include grid-core-span-x($gridColumns, $gridColumnWidth, $gridGutterWidth);
    /* generate .offsetX */
    @include grid-core-offset-x($gridColumns, $gridColumnWidth, $gridGutterWidth);

}
@mixin grid-core-span-x($gridColumns, $gridColumnWidth, $gridGutterWidth) {
    @for $i from 1 through $gridColumns {
        .span#{$i} { @include grid-core-span($i, $gridColumnWidth, $gridGutterWidth); }
    }
}

@mixin grid-core-offset-x($gridColumns, $gridColumnWidth, $gridGutterWidth) {
    @for $i from 1 through $gridColumns {
        .offset#{$i} { @include grid-core-offset($i, $gridColumnWidth, $gridGutterWidth); }
    }
}

@mixin grid-core-span($columns, $gridColumnWidth, $gridGutterWidth) {
    width: ($gridColumnWidth * $columns) + ($gridGutterWidth * ($columns - 1));
}

@mixin grid-core-offset($columns, $gridColumnWidth, $gridGutterWidth) {
    margin-left: ($gridColumnWidth * $columns) + ($gridGutterWidth * ($columns - 1));
}

@mixin grid-fluid($fluidGridColumnWidth, $fluidGridGutterWidth) {
    .row-fluid {

        @include clearfix();
        [class*="span"] {
            @include input-block-level();
            float: left;
            margin-left: $fluidGridGutterWidth;
            *margin-left: $fluidGridGutterWidth - (.5 / $gridRowWidth * 100px * 1%);
        }
        [class*="span"]:first-child {
            margin-left: 0;
        }

        /* Space grid-sized controls properly if multiple per line */
        .controls-row [class*="span"] + [class*="span"] {
            margin-left: $fluidGridGutterWidth;
        }

        /* generate .spanX and .offsetX */
        @include grid-fluid-span-x($gridColumns, $fluidGridColumnWidth, $fluidGridGutterWidth);
        @include grid-fluid-offset-x($gridColumns, $fluidGridColumnWidth, $fluidGridGutterWidth);
    }
}



@mixin grid-fluid-span-x($gridColumns, $fluidGridColumnWidth, $fluidGridGutterWidth) {
    @for $i from 1 through $gridColumns {
        .span#{$i} { @include grid-fluid-span($i, $fluidGridColumnWidth, $fluidGridGutterWidth); }
    }
}

@mixin grid-fluid-offset-x($gridColumns, $fluidGridColumnWidth, $fluidGridGutterWidth) {
    @for $i from 1 through $gridColumns {
        .offset#{$i} { @include grid-fluid-offset($i, $fluidGridColumnWidth, $fluidGridGutterWidth); }
        .offset#{$i}:first-child { @include grid-fluid-offset-first-child($i, $fluidGridColumnWidth, $fluidGridGutterWidth); }
    }
}

@mixin grid-fluid-span($columns, $fluidGridColumnWidth, $fluidGridGutterWidth) {
    width: ($fluidGridColumnWidth * $columns) + ($fluidGridGutterWidth * ($columns - 1));
    *width: ($fluidGridColumnWidth * $columns) + ($fluidGridGutterWidth * ($columns - 1)) - (.5 / $gridRowWidth * 100px * 1%);
}

@mixin grid-fluid-offset($columns, $fluidGridColumnWidth, $fluidGridGutterWidth) {
    margin-left: ($fluidGridColumnWidth * $columns) + ($fluidGridGutterWidth * ($columns - 1)) + ($fluidGridGutterWidth * 2);
    *margin-left: ($fluidGridColumnWidth * $columns) + ($fluidGridGutterWidth * ($columns - 1)) - (.5 / $gridRowWidth * 100px * 1%) + ($fluidGridGutterWidth * 2) - (.5 / $gridRowWidth * 100px * 1%);
}

@mixin grid-fluid-offset-first-child($columns, $fluidGridColumnWidth, $fluidGridGutterWidth) {
    margin-left: ($fluidGridColumnWidth * $columns) + ($fluidGridGutterWidth * ($columns - 1)) + ($fluidGridGutterWidth);
    *margin-left: ($fluidGridColumnWidth * $columns) + ($fluidGridGutterWidth * ($columns - 1)) - (.5 / $gridRowWidth * 100px * 1%) + $fluidGridGutterWidth - (.5 / $gridRowWidth * 100px * 1%);
}


/* RANGES
// We use these functions to define ranges for various things, like media queries. */
@function lower-bound($range) {
    @if length($range) <= 0 {
        @return 0;
    }
    @return nth($range, 1);
}

@function upper-bound($range) {
    @if length($range) < 2 {
        @return 999999999999;
    }
    @return nth($range, 2);
}

/* STRIP UNIT
// It strips the unit of measure and returns it */
@function strip-unit($num) {
    @return $num / ($num * 0 + 1);
}
/* CONVERT TO REM */
@function convert-to-rem($value, $base-value: $rem-base) {
    $value: strip-unit($value) / strip-unit($base-value) * 1rem;
    @if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
    @return $value;
}

/* Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$global-font-size` variable.
/// @access private
///
/// @param {Number} $value - Pixel value to convert.
/// @param {Number} $base [null] - Base for pixel conversion.
///
/// @returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is. */
@function -zf-to-rem($value, $base: null) {
    /* Check if the value is a number */
    @if type-of($value) != 'number' {
        @warn inspect($value) + ' was passed to rem-calc(), which is not a number.';
        @return $value;
    }

    /* Calculate rem if units for $value is not rem */
    @if unit($value) != 'rem' {
        $value: strip-unit($value) / strip-unit($base) * 1rem;
    }

    /* Turn 0rem into 0 */
    @if $value == 0rem {
        $value: 0;
    }

    @return $value;
}

/* Converts one or more pixel values into matching rem values.
///
/// @param {Number|List} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.
/// @param {Number} $base [null] - The base value to use when calculating the `rem`. If you're using Foundation out of the box, this is 16px. If this parameter is `null`, the function will reference the `$base-font-size` variable as the base.
///
/// @returns {List} A list of converted values. */
@function rem-calc($values, $base: null) {
    $rem-values: ();
    $count: length($values);

    /* If no base is defined, defer to the global font size */
    @if $base == null {
        $base: $global-font-size;
    }

    /* If the base font size is a %, then multiply it by 16px
    // This is because 100% font size = 16px in most all browsers */
    @if unit($base) == '%' {
        $base: ($base / 100%) * 16px;
    }

    @if $count == 1 {
        @return -zf-to-rem($values, $base);
    }

    @for $i from 1 through $count {
        $rem-values: append($rem-values, -zf-to-rem(nth($values, $i), $base));
    }

    @return $rem-values;
}




@function em-calc($values) {
    $remValues: rem-calc($values);

    $max: length($remValues);

    @if $max == 1 { @return strip-unit(nth($remValues, 1)) * 1em; }

    $emValues: ();
    @for $i from 1 through $max {
        $emValues: append($emValues, strip-unit(nth($remValues, $i)) * 1em);
    }
    @return $emValues;
}

