.widget-buttons {

  .button {
    display: inline-block;

    > span {
      padding: 0 4px;

    }
  }

  &[data-backgroundcolor] .button {
    background-color: var(--button-bg);
  }

  &[data-color] .button > span {
    color: var(--button-color, #fff);
  }

  &[data-backgroundcolor-hover] .button:hover {
    background-color: var(--button-bg-hover);
  }

  &[data-color-hover] .button:hover > span {
    color: var(--button-color-hover, #fff);
  }

  $btn-color: #696969;

  .button-custom {
    display: inline-block;
    position: relative;
    text-decoration: none;
    padding: 0.4em 1.4em;
    box-sizing: border-box;
    color: $btn-color;
    > span {
      padding: 0 4px;
    }
  }


  &--design-1 {
    .button-custom {
      & {
        background-color: var(--button-bg, currentColor);
        color: $btn-color;
        border: 1px solid var(--button-bg, currentColor);
        box-shadow: 0 2px 0 var(--button-bg, currentColor), 2px 4px 6px var(--button-bg, currentColor);
        letter-spacing: 1px;
        -webkit-transition: all 150ms linear;
        transition: all 150ms linear;

        span {
          color: var(--button-color, #fff);
        }
      }
      &:hover {
        background: var(--button-bg-hover, currentColor);
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.2);


        text-decoration: none;
        text-shadow: -1px -1px 0  var(--button-color-hover, currentColor);
        -webkit-transition: all 250ms linear;
        transition: all 250ms linear;
        span {
          color: var(--button-color-hover, #fff);
        }
      }

    }

    &:not([data-backgroundcolor]) .button-custom span {
      color: #fff;
    }
  }


  &--design-2 {
    .button-custom {
      transition: 0.5s;
      overflow: hidden;
      z-index: 1;
      border: 1px solid var(--button-bg, currentColor);
      span {
        color: var(--button-color, currentColor);
      }
      &:before {
        box-sizing: border-box;
        transition: 0.5s;
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        right: auto;
        bottom: auto;
        left: 0;
        width: 0;
        height: 100%;
        background: var(--button-bg, currentColor);
      }

      &:hover {

        span {
          color: var(--button-color-hover, #fff);
        }

        &:before {
          width: 100%;
        }
      }

      &:active {
        background: var(--button-bg, currentColor);
      }
    }
  }
  &--design-3 {
    .button-custom {
      transition: 0.5s;
      overflow: hidden;
      z-index: 1;
      border: 1px solid var(--button-bg, currentColor);

      color: var(--button-color, $btn-color);
      &:after {
        box-sizing: border-box;
        transition: 0.5s;
        z-index: -1;
        content: '';
        width: 0;
        height: 0;
        -webkit-transform: rotate(360deg);
        border-style: solid;
        border-width: 0 0 0 0;
        border-color: var(--button-bg, currentColor) transparent transparent transparent;
        position: absolute;
        top: 0;
        left: 0;
      }

      &:hover {
        span {
          color: var(--button-color-hover, #fff);
        }


        &:after {
          border-width: 330px 330px 0 0;
        }
      }

      &:active {
        background: var(--button-bg, currentColor);
      }
    }
  }

  &--design-4 {
    .button-custom {
      transition: 0.5s;
      overflow: hidden;
      z-index: 1;
      border: 1px solid var(--button-bg, currentColor);

      color: var(--button-color, currentColor);
      &:before {
        box-sizing: border-box;
        transition: 0.5s;
        z-index: -1;
        content: '';
        width: 0;
        height: 0;
        -webkit-transform: rotate(360deg);
        border-style: solid;
        border-width: 0 0 0 0;
        border-color: transparent transparent transparent var(--button-bg, currentColor);
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &:after {
        box-sizing: border-box;
        transition: 0.5s;
        z-index: -1;
        content: '';
        width: 0;
        height: 0;
        -webkit-transform: rotate(360deg);
        border-style: solid;
        border-width: 0 0 0 0;
        border-color: transparent transparent var(--button-bg, currentColor) transparent;
        position: absolute;
        right: 0;
        bottom: 0;
      }

      &:hover {
        span {
          color: var(--button-color-hover, #fff);
        }

        &:before {
          border-width: 330px 0 0 330px;
        }
        &:after {
          border-width: 0 0 330px 330px;
        }
      }

      &:active {
        background: var(--button-bg-hover, currentColor);
      }
    }
  }

  &--design-5 {
    .button-custom {
      transition: 0.5s;
      overflow: hidden;
      z-index: 1;
      border: 1px solid var(--button-bg, currentColor);

      color: var(--button-color, $btn-color);
      &:before {
        box-sizing: border-box;
        transition: 0.5s;
        z-index: -1;
        content: '';
        width: 0;
        height: 0;
        -webkit-transform: rotate(360deg);
        border-style: solid;
        border-width: 0 0 0 0;
        border-color: transparent transparent transparent var(--button-bg, currentColor);
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &:after {
        box-sizing: border-box;
        transition: 0.5s;
        z-index: -1;
        content: '';
        width: 0;
        height: 0;
        -webkit-transform: rotate(360deg);
        border-style: solid;
        border-width: 0 0 0 0;
        border-color: transparent var(--button-bg, currentColor) transparent transparent;
        position: absolute;
        top: 0;
        right: 0;
      }

      &:hover {
        span {
          color: var(--button-color-hover, #fff);
        }

        &:before {
          border-width: 330px 0 0 330px;
        }
        &:after {
          border-width: 0 330px 330px 0;
        }
      }

      &:active {
        background: var(--button-bg-hover, currentColor);
      }
    }
  }

  &--design-6 {
    .button-custom {
      transition: 0.5s;
      overflow: hidden;
      z-index: 1;
      border: 1px solid var(--button-bg, currentColor);

      color: var(--button-color, $btn-color);
      &:before, &:after {
        content: '';
        box-sizing: border-box;
        transition: 0.5s;
        z-index: -1;

        position: absolute;
        top: 50%;
        width: 20px;
        height: 20px;
        background: var(--button-bg, currentColor);
        border-radius: 50%;

      }
      &:before {
        left: -20px;
        transform: translate(-50%, -50%);
      }
      &:after {
        right: -20px;
        transform: translate(50%, -50%);
      }

      &:hover {
        span {
          color: var(--button-color-hover, #fff);
        }

        &:before {
          animation: criss-cross-left 0.8s both;
          animation-direction: alternate;
        }
        &:after {
          animation: criss-cross-right 0.8s both;
          animation-direction: alternate;
        }
      }

      &:active {
        background: var(--button-bg-hover, currentColor);
      }
    }
  }

  @keyframes criss-cross-right {
    0% {
      right: -20px;
    }
    50% {
      right: 50%;
      width: 20px;
      height: 20px;
    }
    100% {
      right: 50%;
      width: 375px;
      height: 375px;
    }
  }


  @keyframes criss-cross-left {
    0% {
      left: -20px;
    }
    50% {
      left: 50%;
      width: 20px;
      height: 20px;
    }
    100% {
      left: 50%;
      width: 375px;
      height: 375px;
    }
  }

  &--design-7 {
    .button-custom {
      & {
        border: 2px solid var(--button-bg, currentColor);
        transition: color 0.4s
      }
      &:after {
        content: " ";
        border: 2px solid rgba(0, 0, 0, 0);
        display: inline-block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        transition: all 0.4s;
      }
      &:hover {
        color: var(--button-color-hover, currentColor)
      }
      &:hover:after {
        border: 2px solid var(--button-bg-hover, currentColor);
        width: calc(100% - 10px);
        height: calc(100% + 10px);
      }
    }
  }
}