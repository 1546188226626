/*
    Created on : 19 sept. 2014, 09:46:48
    Author     : Damien T. - Lord of Web
*/
/* Importation des fonctions réutilisable Sass */
@import "cmonsite/main_mixin";

pre {
  white-space: normal;
}

/* General */
.center {
  text-align: center;
}

.info-livreor-no-msg {
  margin-top: 20px;
  margin-bottom: 20px;
}

#headercontent {
  position: relative;
}

.diaporama {
  clear: both;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;

  > .button i {
    margin-right: 10px;
  }
}

.bxslider-diaporama {
  padding: 0;
  margin: 0;

  > li {
    text-align: center;
    list-style-type: none !important;

    img {
      display: inline-block;
    }
  }
}

.lightTip > .content {
  margin: 0 !important;
}

/*
			TOOLTIP
		*/

.cmonsite-tooltip {
  font-size: 0.875em;
  text-align: center;
  line-height: 1.5;
  border: 6px solid #e2e2e2;
  background: #fff;
  color: #020202;
  border-radius: 5px;
  position: absolute;
  z-index: 100;
  padding: 15px;
}

.cmonsite-tooltip:after {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #e2e2e2;
  content: '';
  position: absolute;
  left: 50%;
  bottom: -16px;
  margin-left: -10px;
}

.cmonsite-tooltip.top:after {
  border-top-color: transparent;
  border-bottom: 10px solid #333;
  top: -20px;
  bottom: auto;
}

.cmonsite-tooltip.left:after {
  left: 10px;
  margin: 0;
}

.cmonsite-tooltip.right:after {
  right: 10px;
  left: auto;
  margin: 0;
}

.clearfix {
  @include clearfix;
}

/* Login */
.protectionpassmsg {
  text-align: center;
  border: 1px solid black;
  width: 300px;
  margin: 0 auto;
  padding: 10px;
  background: red;
  color: white;
  border-bottom: none;
  font-weight: bold;
}

.protectionpass {
  text-align: center;
  border: 1px solid black;
  width: 360px;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 30px;
  background: white;
  color: black;
}

.protectionpass .wrongpass {
  margin-top: 10px;
  font-weight: bold;
  color: red;
  border-radius: 0;
}

.protectionpass input[type=password] {
  padding: 5px;
  border: 1px solid black;
  background: #eee;
  border-radius: 0;
}

.protectionpass input[type=submit] {
  padding: 4px;
  border: 1px solid black;
  background: black;
  color: white;
  cursor: pointer;
  border-radius: 0;
}

@media screen and (max-width: 500px) {
  .protectionpass {
    padding: 20px 5%;
  }
}

/* Formulaires */
/*.formulaires_perso, .form-newsletter{font-family:arial,sans-serif !important;}    */

.formulaires_perso input, .formulaires_perso select, .formulaires_perso textarea {
  padding: 5px 7px;
  border: 1px solid #ccc;
}

.formulaires_perso input[type=text] {
  width: 80%;
}

.formulaires_perso textarea {
  width: 80%;
  font-family: arial, sans-serif;
  color: black;
  font-size: 12px;
}

.formulaires_perso input[type=submit], .form-newsletter input[type=submit] {
  font-weight: bold;
  background: #444;
  color: white;
  cursor: pointer !important;
  font-size: 12px;
}

input[type=submit] {
  -webkit-appearance: none;
  border-radius: 0;
}

.formulaires_perso .qs_form, .form-newsletter .qs_form {
  display: block !important;
  margin-bottom: 10px !important;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  margin-top: 5px;
  width: 100%;
}


.formulaires_perso .formulaire-error, .form-newsletter .formulaire-error {
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #ebccd1;
  border-radius: 4px;
  color: #a94442;
  background-color: #f2dede;
  font-weight: 700;
  text-align: center;
}

.formulaires_perso input[type=checkbox] {
  margin-bottom: 15px;
}

.formulaires_perso input[type=text].hasDatepicker {
  width: 100px;
}

.form-newsletter {
  margin-top: 15px;
}

.form-newsletter .center {
  margin-bottom: 10px;
}

.form-newsletter input {
  padding: .8%;
  border: 1px solid #ccc;
  margin: 3px 0;
}

.form-newsletter div:last-child {
  //width: 95%;
  //margin: auto;
}

/*.roundedgraymsg{-moz-border-radius:5px;-webkit-border-radius:5px;border-radius:5px;padding:15px;background:#eaeaea;margin-bottom:25px;font-weight:bold;border:1px solid #ccc; position : relative;}*/

.roundedgraymsg {
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 25px;
  font-weight: bold;
  border: 1px solid #ccc;
  position: relative;
}

/* Calendriers */
table.fc-header {
  border: none !important;
}

table.fc-header tbody tr td {
  border: none !important;
}

table.fc-agenda-allday {
  margin: 0 !important;
}

/* Boutique */

.mode-boutique {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

.nomprod {
  font-weight: bold;
  line-height: 1em;
}

#fiche-produit .produit_etiquette {
  position: relative;

  .h3 {
    margin-bottom: 0;
  }
}

.produit_etiquette {
  .h3.titre-etiquette {
    .fa {
      margin-right: 3px;
    }
  }
}

.produits p.titreprod {
  font-size: 0.9em;
  line-height: 1em;
}

.prixprod {
  font-weight: bold;
  font-size: 1.1em;
}

.produits img {
  margin: 0;
}

.produits p {
  margin: 5px 0;
}

.produits select {
  width: 100%;
}

.produits p.attr {
  height: auto;
}

#GoogleMapAffichage {
  color: black;
}

.gm-style img,
.content_p .gm-style img,
#contentpage .gm-style img,
#wrapper .gm-style img {
  max-width: none !important;
}

a img {
  border: none;
  vertical-align: middle;
}

.logo {
  display: inline-block;

  img {
    max-width: 100%;
  }
}

.slogan {
  min-height: 20px;
  min-width: 50px;
  max-width: 100%;
  overflow-y: hidden;

  p {
    margin: 0 auto;
    line-height: initial;
  }
}

.hors-stock {
  color: #FF0000;
  display: block;
  font-size: 15px;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
}

.block-btn-addbasket {
  clear: both;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.produit_etiquette h3, .produit_etiquette2 h3, .produit_etiquette3 h3,
.produit_etiquette .h3, .produit_etiquette2 .h3, .produit_etiquette3 .h3 {
  text-align: center;
  padding: 8px 0;
  color: white;
  margin: 0;
  font-size: 1rem;
}

/*.is_etiquette .remise{
    top:90px;
}*/
/*bannière triangle*/
.produit_etiquette {
  position: absolute;
  background: #4181d9;
  width: 144px;
  height: 40px;
  margin-top: 0;
}

.produit_etiquette:after {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.produit_etiquette:after {
  border-color: rgba(65, 129, 217, 0);
  border-top-color: #4181d9;
  border-width: 15px;
  left: 50%;
  margin-left: -15px;
}

/* etiquette  2 */
.produit_etiquette2 {
  background-color: #4181D9;
  height: 40px;
  width: 100%;
  position: relative;
  right: 20px;
  z-index: 2;
  bottom: 20px;
}

.produit_etiquette2:after {
  content: '';
  position: absolute;
  z-index: 1;
  border-style: solid;
  height: 0;
  width: 0;
  top: 40px;
  left: 0;
  border-color: transparent #174A97 transparent transparent;
  border-width: 0 20px 10px 0;
}

.fa.fa-flag, .fa.fa-bolt, .fa.fa-bookmark {
  margin-right: 5px;
}

.text-livre {
  margin: 0 20px 30px 20px;
}

@font-face {
  font-family: 'fontello';
  src: url("../font/fontello/fontello.eot?92423409");
  src: url("../font/fontello/fontello.eot?92423409#iefix") format("embedded-opentype"), url("../font/fontello/fontello.woff?92423409") format("woff"), url("../font/fontello/fontello.ttf?92423409") format("truetype"), url("../font/fontello/fontello.svg?92423409#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?92423409#fontello') format('svg');
  }
}
*/

.produit_etiquette [class^="icon-"]:before, .produit_etiquette [class*=" icon-"]:before,
.produit_etiquette2 [class^="icon-"]:before, .produit_etiquette2 [class*=" icon-"]:before,
.produit_etiquette3 [class^="icon-"]:before, .produit_etiquette3 [class*=" icon-"]:before {
  font-family: "fontello", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.produit_etiquette .icon-star:before,
.produit_etiquette2 .icon-star:before,
.produit_etiquette3 .icon-star:before {
  content: '\e802';
}

/* '?' */
.produit_etiquette .icon-heart:before,
.produit_etiquette2 .icon-heart:before,
.produit_etiquette3 .icon-heart:before {
  content: '\e805';
}

/* '?' */
.produit_etiquette .icon-plus-circled:before,
.produit_etiquette2 .icon-plus-circled:before,
.produit_etiquette3 .icon-plus-circled:before {
  content: '\e800';
}

/* '?' */
.produit_etiquette .icon-plus:before,
.produit_etiquette2 .icon-plus:before,
.produit_etiquette3 .icon-plus:before {
  content: '\e804';
}

/* '?' */
.produit_etiquette .icon-basket:before,
.produit_etiquette2 .icon-basket:before,
.produit_etiquette3 .icon-basket:before {
  content: '\e803';
}

/* '?' */
.produit_etiquette .icon-bookmark-empty:before,
.produit_etiquette2 .icon-bookmark-empty:before,
.produit_etiquette3 .icon-bookmark-empty:before {
  content: '\e806';
}

/* '?' */
.produit_etiquette .icon-thumbs-up-alt:before,
.produit_etiquette2 .icon-thumbs-up-alt:before,
.produit_etiquette3 .icon-thumbs-up-alt:before {
  content: '\e801';
}

/* '?' */

/* PAGE MARQUE */

.marque-desc {
  margin: 5px 0 25px;
}

.container-comment {
  width: 100%;
}

.marque-center {
  display: block;
  width: 100%;
  text-align: center;
  padding: 10px 0;

  img {
    display: inline-block;
  }
}

/* --------------------------------------------------------------
 *
 * Font_awesome
 *
  -------------------------------------------------------------- */
@import "font_awesome/font-awesome";

/* Bouton "Retour sur l'administration" */
.link_return_cmonsite, .link_return_cmonsite:visited {
  background: none repeat scroll 0 0 #fff;
  border: 4px solid #b9d52e;
  border-radius: 30px;
  bottom: 10px;
  color: #444;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 0 3px;
  position: fixed;
  right: 360px;
  text-align: left;
  text-decoration: none;
  width: 225px;
  z-index: 10000;
  font-family: arial, sans-serif;
}

.link_return_cmonsite:hover {
  background: none repeat scroll 0 0 #eee;
}

.link_return_cmonsite .fa-stack {
  float: left;
  margin: 0 5px;
}

.link_return_titre {
  margin-top: 2px;
}

.link_return_desciption {
  font-size: 10px;
  font-style: italic;
  font-weight: lighter;
}

/* Button de recherche */
.content-cmonsite.form-search * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.content-cmonsite.form-search *:before,
.content-cmonsite.form-search *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#block-flottant-search input[type="text"] {
  border: 0 none;
  padding: 0;
  transition: all 0.5s ease-in-out;
  width: 0;
  margin: 0;
  height: 36px;
  display: inline-block;
  position: absolute;
  vertical-align: middle;
  right: 34px;
}

#block-flottant-search.actif input[type="text"] {
  border: 1px solid #bbb;
  padding: 7px;
  width: 152px;
}

#block-flottant-search .btn-search {
  background-image: none;
  border: 1px solid transparent;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857;
  margin-bottom: 0;
  padding: 6px 12px 6px 8px;
  position: absolute;
  right: 0;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 35px;
  height: 36px;
  top: 0;
  margin-top: 0;
}

#block-flottant-search .input-group {
  margin-left: -161px;
  position: relative;
  width: 196px;
}

.btn {
  margin: 0;
}

.nomprod a {
  color: inherit;
}

.inmap, .alone {
  .adresse .adr {
    text-align: center;
    display: block;
    bottom: 0;
    width: 100%;
    margin-top: auto;
  }
}

@import "cmonsite/main_gestion_commentaires_produits";

/* Barre de recherche */

.search-bar {
  width: 100%;
  display: table;
  table-layout: fixed;

  input, .search-bar-icon {
    display: table-cell;
    vertical-align: bottom;
  }

  input, button {
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-collapse: collapse;
  }

  input {
    color: #555;
    border-radius: 4px 0 0 4px;
    border-right: 1px solid transparent;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    width: 100%;
    line-height: 24px;
    height: auto !important;
  }

  .search-bar-icon {
    width: 5%;
  }

  button {
    color: #000;
    border-radius: 0 4px 4px 0;
    background-color: #fff;
    cursor: pointer;
    margin: 0;
    display: inline-block;
    height: 38px;

    &:hover {
      background-color: #ccc;
    }
  }
}

.content form h2,
.content form .h2 {
  margin-bottom: 20px;
}

form {
  a {
    text-decoration: underline;
  }

  button, .button {
    margin-top: 18px;
    margin-bottom: 15px;
    display: inline-block;
  }
}

.form-group {
  padding-right: 10px;
  margin-bottom: 12px;

  label {
    display: block;

    + .groupe-checkbox {
      margin-top: 10px;
    }
  }

  > label {
    font-weight: bold;
  }

  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="url"],
  input[type="search"],
  input[type="email"],
  input[type="file"],
  input[type="date"],
  input[type="datetime"],
  input[type="password"],
  input[type="number"],
  textarea,
  select {
    width: 100%;
    margin: 3px 0;
  }

  .groupe-radio, .groupe-checkbox {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    > * {
      flex: 0 0 auto;
    }

    label {
      flex: 1 1 auto;
      display: inline-block;
      vertical-align: middle;
      margin-left: 8px;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
      font-weight: normal;
    }

    input[type="radio"], input[type="checkbox"] {
      display: inline-block;
      width: auto;
      height: auto;
      vertical-align: middle;
      margin-top: 0;
    }

    .formError {
      + input[type="radio"], + input[type="checkbox"] {
        margin-top: 1em;
      }
    }
  }

  &[data-type="casecocherunique"],
  &[data-type="casecochermultiple"] {
    input {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
  }
}

// TINYMCE IMAGES STYLE

img.circle {
  border-radius: 50%;
}

img.ronde {
  border-radius: 25px;
}

img.semi-ronde {
  border-top-left-radius: 2em;
  border-bottom-right-radius: 2em;
}

img.bulle {
  border-top-left-radius: 10em;
  border-bottom-right-radius: 10em;
  border-bottom-left-radius: 10em;
}

img.ombre {
  box-shadow: 6px 6px 12px 0 #000000;
}

img.photo-imprime {
  padding: 5px;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
}

img.polaroid {
  padding: 5px 5px 40px;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
}

// FIN TINYMCE IMAGES STYLE

.ajout_panier .panier_p {
  margin-left: 0;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.table {
  width: 100%;
}

.table thead td, .table thead th {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}

.commandes-details .infosclient {
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.commandes-details .infosclient h4,
.commandes-details .infosclient .h4 {
  border-bottom: 1px solid #C0C0C0;
  color: #333;
  font-size: 14px;
  margin: 10px 0 10px;
  padding: 0 0 5px;
}

.commandes-details .infosclient .row {
  margin: 10px 0;
}

.commandes-details .infosclient .row.dotted {
  border-bottom: 1px dotted #C0C0C0;
}

.commandes-details .infosclient {
  &__questions {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;

    &__item {
      width: 25%;
      flex: 0 1 auto;
      padding: 5px;

      &__content {
        border: 1px solid #e1e1e1;
        padding: 10px 15px;
      }
    }

  }
}

@media screen and (max-width: 1024px) {
  .commandes-details .infosclient {
    &__questions {

      &__item {
        width: 50%;
      }

    }
  }
}
@media screen and (max-width: 768px) {
  .commandes-details .infosclient {
    &__questions {

      &__item {
        width: 100%;
      }

    }
  }
}

/* GRID pour minisite (sans box-sizing générale pour éviter les conflits) */
@import "design/global";
@import "design/grid";

@import "cmonsite/side-menu";

.contents-tabs::before {
  border-color: transparent;
}

@import "style-all-design/editor/image";
@import "style-all-design/editor/horizontal-rules";
@import "style-all-design/editor/video";
@import "style-all-design/editor/grid";
@import "style-all-design/editor/price-list";
@import "style-all-design/editor/button";
@import "style-all-design/editor/spacer";
@import "style-all-design/editor/before-after";
@import "style-all-design/editor/actualites";
@import "style-all-design/editor/categories-thumbnail";
@import "style-all-design/editor/instagram-feed/templates";
@import "style-all-design/diaporama/carouselticker";
@import "style-all-design/components/faceting";
@import "style-all-design/components/image";
@import "style-all-design/vendor/tarteaucitron/tarteaucitron-minisite";

.content_p {

}

.side-tabs.top a.active:after {
  background: #C0C0C0;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

/*  GESTION PROFIL CLIENTS  */

.profile {
  a.button, button {
    padding: 5px 16px;
    font-size: 17px;
    cursor: pointer;
    margin: 0;
    display: inline-block;

  }
}

.inscription {
  margin-bottom: 15px;
}

.connexion a {
  margin-bottom: 5px;
  display: inline-block;
}

#connexion-inscription {
  form {
    padding: 5px 10px;
    overflow: hidden;

    a {
      margin-left: .6em;
    }
  }

  .button {
    float: right;
  }

  h2, .h2 {
    margin-bottom: 7px;
    font-size: 22px;
    text-transform: none;
  }

  p {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 1.1em;
  }
}

#order {
  table {
    margin: 25px 0;
    border-collapse: collapse;
  }

  .commandes-all > thead th:first-child {
    text-align: left;
  }

  .commandes {
    //height: 35px;

    td {
      text-align: center;
      border-top: 1px solid #ccc;
      vertical-align: middle;
      padding-top: 5px;
      padding-bottom: 5px;

      &:first-child {
        text-align: left;
      }
    }

    .button {
      vertical-align: middle;
    }
  }

  .commandes-details {

  }
}

.commandes-details__content {
  padding-bottom: 25px;
}

@media screen and (max-width: 768px) {
  #order {
    .commandes-details .col.s6 {
      width: 100% !important;
      display: block !important;
      float: none !important;
    }

    table {
      width: 100%;
    }

    thead {
      display: none;
    }

    tr {
      height: auto !important;
    }

    td {
      padding: .5em 1em;
      display: block;
      text-align: left;
      width: 100% !important;
      max-width: 100% !important;
      border-top: none;
    }

    tr {
      border-bottom: 1px solid #ccc;
      display: block;
    }

    table.table tr > td[data-th]:not([data-th='']):before {
      content: attr(data-th);
      font-weight: bold;
      min-width: 30%;
      margin-right: 5px;
      display: inline-block;
      text-align: left;
    }

    .commandes {
      padding-bottom: 8px;
      padding-top: 8px;

      td {
        border-top: none;
        text-align: left !important;
      }
    }

    .commandes-details {
      border-bottom: none;

      td {
        padding: 0;
      }
    }


    .infosclient td {
      display: block !important;
      width: 100% !important;
      max-width: 100% !important;

      &.text-center {
        text-align: right;
      }
    }

    .button {
      float: none !important;
    }
  }

  .commandes-details__cart tr {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}


#mailbox-form {
  margin-bottom: 25px;

  .button {
    display: inline-block;
  }

  > div > div:last-child {
    text-align: right;
  }
}

#mailbox {
  > div {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    margin-bottom: -1px;

    .col.s9 {
      padding: 9px 7px;
    }

    .col.s3 {
      padding: 4px 0;
    }

    div[class|=detail-mailbox] {
      margin-bottom: 25px;
      margin-top: 10px;

      .message-wrap {
        margin-left: 20px;
      }

      .pull-right {
        margin-right: 20px;
      }

      textarea {
        height: 80px;
      }
    }
  }

  a.button {
    display: inline-block;
    margin-top: 0;
  }
}

#forgot-password {
  padding: 5px 25px;

  button {
    display: inline;
    margin-left: 3px;
    cursor: pointer;
  }
}

/* Fiche produit Pop-up
Ici pour que la fancybox calcule avant que le css de la fiche produit arrive
*/
.fancybox-wrap #fiche-produit {
  margin: 15px;
  position: relative;
  /*
  Cette valeur peut-être changer si on ajoute d'autre fonctionnalité dans la fiche produit en popup
  C'est un Fix pour Fancybox
  */
  max-height: 475px;
}

/* Formulaire Error */
.error {
  label {
    color: #F04124 !important;
  }

  input {
    border-color: #F04124 !important;
  }
}

.error.error-product {
  color: #a94442;
  background-color: #f2dede;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ebccd1;
  border-radius: 4px;
  text-align: center;
}

/* Widget partage Bug bouton facebook pas aligné */
.fb-share-button {
  vertical-align: top;
}

/********************************/
/************* BLOG *************/
/********************************/

/********** Une colonne *********/

/*page avec tous les articles*/
.apercu-article {
  position: relative;
  margin-bottom: 50px;

  h2, .h2 {
    &.titre-article {
      width: 300px;
      position: absolute;
      top: 100px;
      left: 0;
      text-transform: uppercase;
      display: inline;
      z-index: 10;
      text-align: right;
      padding: 10px;
      margin: 0 !important;
      background: #fff;
    }

    &.titre-article-no-image {
      text-transform: uppercase;
      padding: 10px;
    }

  }

  .bloc-article {
    width: 100%;
    float: right;
    position: relative;
    z-index: 1;
    margin: 20px 0;

    .image-article {
      overflow: hidden;
      width: 100%;
      text-align: center;

      img {
        max-width: 100%;
        opacity: .7;
        height: auto;
        transition: all .5s ease;
        margin: 0;
      }
    }

    .description-courte-article {
      width: 100%;
      padding: 20px 20px 0;

      margin: 0 auto;
      display: block;
      box-sizing: border-box;

      p {
        margin-bottom: 20px;

        &.date-article {
          text-align: right;
          opacity: .4;
          font-weight: bold;
          font-style: italic;
          float: left;
          display: inline-block;
        }
      }

      .short-article-social-share {
        float: right;
      }

      .mots-cles {
        width: 100%;
        display: block;
        margin-bottom: 20px;

        .titre-mots-cles {
          font-weight: bold;
          text-decoration: underline;
          margin-bottom: 10px;
        }

        .short-article-tag {
          padding: 0;
          margin: 5px;
          display: inline-block;
          float: left;
          position: relative;

          > a {
            display: block;
            padding: 5px;
            position: relative;
            z-index: 2;
          }

          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.05);
          }
        }
      }

      a.button.button-voir-plus {
        text-decoration: none;
        text-transform: uppercase;
        padding: 5px;
        transition: background .2s linear;
        float: right;
        width: auto !important;
        margin-left: 0 !important;
        clear: none !important;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    .bloc-article {
      .image-article {
        img {
          //max-width:110%;
          opacity: 1;
        }
      }
    }
  }
}

/*Page de l'article*/

.single-article {
  position: relative;
  padding: 30px;

  .image-article {
    width: 100%;
    position: relative;

    img {
      width: 100%;
    }
  }

  .titre-article-no-image {
    h1, .h1 {
      text-transform: uppercase;
    }

    p.date-article {
      text-align: left;
      margin-bottom: 20px;
    }
  }

  .titre-article {
    position: relative;
    top: -25px;
    width: 80%;
    left: 10%;
    padding-bottom: 10px;

    h1, .h1 {
      width: 100%;
      background: #fff;
      text-transform: uppercase;
      display: inline-block;
      z-index: 10;
      padding: 10px;
      text-align: center;
      margin: 0 !important;
      box-sizing: border-box;
    }
  }

  .contenu-article {
    width: 100%;
    position: relative;

    img {
      max-width: 100% !important;
      height: auto !important;
    }

    .mots-cles {
      width: 100%;
      display: block;
      margin-bottom: 20px;

      .titre-mots-cles {
        font-weight: bold;
        text-decoration: underline;
        margin-bottom: 10px;
      }

      .short-article-tag {
        padding: 5px;
        margin: 5px;
        display: inline-block;
        float: left;
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    .social-share {
      float: right;
      margin: 0 10px;
    }
  }

  p {
    margin-bottom: 20px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
    display: block;

    &.date-article {
      text-align: center;
      padding-top: 10px;
      opacity: .4;
      font-weight: bold;
      font-style: italic;
      display: inline-block;
      margin-bottom: 0;
    }
  }
}

/*Menu Blog*/
.blog-sidebar {
  .blog-sidebar-bloc {
    h4, .h4 {
      text-align: center;
      padding: 10px 0;
    }

    ul {
      margin-left: 0 !important;

      li {
        list-style-type: none !important;
        margin: 5px;
        text-align: center;
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.05);
        }

        > a {
          padding: 5px;
          display: block;
          position: relative;
          z-index: 2;
        }

        .nouveaux-articles {
          opacity: .5;
          transition: opacity .3s linear;

          img {
            width: 100%;
            margin: 0 !important;
          }

          .titre-article {
            text-align: center;
            padding: 5px;
          }

          &:hover {
            opacity: 1;
          }
        }
      }

      &::after {
        content: '';
        display: block;
        clear: both;
      }
    }

    &.nouveaux-articles {
      ul {
        li {
          width: 100%;
        }
      }
    }

    &.blog-tags {
      ul {
        li {
          margin: 0 0 5px 5px;
          float: left;
        }
      }
    }
  }
}

.row.one-column-blog, .row.two-column-blog {
  h1, .h1 {
    margin-bottom: 30px;
  }
}

/*Colonnes blog*/
.row.one-column-blog {
  padding: 20px;
  box-sizing: border-box;

  .blog-sidebar {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    padding: 2%;

    .blog-sidebar-bloc {
      width: 33.33%;
      float: left;

      ul {
        padding-left: 0;
      }

      &:last-child {
        float: right;
      }

      &.nouveaux-articles {
        ul {
          li {
            width: auto;
          }
        }
      }
    }
  }

  > .col.s8 {
    width: 100%;
    position: relative;

    .blog-center {
      width: 100%;
      text-align: center;
    }

    .one_image {
      text-align: center;
    }
  }

  .blog-sidebar {
    ul {
      li {
        .nouveaux-articles {
          opacity: 1;

          img {
            display: none;
          }

          .titre-article {
            padding: 0;
          }
        }
      }
    }
  }
}

.row.two-column-blog, .search-article.row {
  padding: 20px;
  box-sizing: border-box;

  .blog-sidebar {
    margin-bottom: 20px;
    position: relative;
    float: right;

    ul {
      padding: 0;
    }

    .blog-sidebar-bloc {
      clear: both;
    }
  }

  > .col.s8 {
    position: relative;

    .blog-center {
      width: 100%;
      text-align: center;
    }

    .one_image {
      text-align: center;
    }
  }

  .apercu-article {
    h2, .h2 {
      &.titre-article {
        width: 250px;
        position: absolute;
        top: 50px;
        text-align: right;
      }
    }

    .bloc-article {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .row.one-column-blog, .row.two-column-blog {
    .blog-sidebar {
      width: 100%;
      margin-bottom: 20px;
      position: relative;

      .blog-sidebar-bloc {
        width: 100%;
        float: left;
        clear: none;

        &:last-child {
          float: right;
        }

        ul {
          padding-left: 0;

          li {
            .nouveaux-articles {
              opacity: 1;

              img {
                display: none;
              }

              .titre-article {
                padding: 0;
              }
            }
          }
        }

        &.nouveaux-articles {
          ul {
            li {
              width: auto;
            }
          }
        }
      }
    }

    .apercu-article {
      h2, .h2 {
        &.titre-article {
          width: 50%;
          top: 50px;
        }
      }

      .bloc-article {
        width: 100%;
      }
    }

    > .col.s8 {
      width: 100%;
      position: relative;

      .blog-center {
        width: 100%;
        text-align: center;
      }

      .one_image {
        text-align: center;
      }
    }

    .short-article-social-share {
      width: auto;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 640px) {
  .apercu-article .bloc-article .description-courte-article {
    padding: 20px 0 0;
  }
  .row.one-column-blog, .row.two-column-blog, .search-article.row {
    .apercu-article {
      h2, .h2 {
        &.titre-article-apercu {
          position: relative;
          width: 100%;
          box-sizing: border-box;
          top: 0;
          margin-bottom: 15px !important;
          display: block;
          text-align: left;
        }
      }
    }
  }
}

@import "bootstrap/variables";
@import "bootstrap/mixins";
@import "bootstrap/thumbnails";
@import "bootstrap/alerts";
@import "bootstrap/pagination";
@import "bootstrap/tooltip";
@import "bootstrap/modals";
@import "bootstrap/close";

.pagination > li > a:hover, .pagination > li > a:focus, .pagination > li > span:hover, .pagination > li > span:focus {
  color: inherit;
}

.pagination.pull-right li.active a {
  color: #fff !important;
}

@media screen and (max-width: 500px) {
  #minisite-sidebar, #block-flottant-search {
    display: none;
  }
}

.button.button-rectangulaire {
  border-radius: 0;
}

.button.button-rounded {
  border-radius: 50px;
}

.button.button-cercle {
  position: relative;

  &:before {
    content: "";
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
  }

}

.preview {
  margin: 10px 0;
}

.preview ul {
  margin: 0 !important;
}

/*****************************************/
/****************** FAQ ******************/
/*****************************************/

.faq {
  .faq_block {
    margin-bottom: 15px;

    a {
      outline: none;
      text-decoration: none;

      &:visited {
        opacity: .5;
      }

      &.question-active {
        .faq_question::before {
          content: '\f01a';
        }

        &:hover {
          .faq_question::before {
            content: '\f18e';
          }
        }
      }

      &:hover {
        .faq_question::before {
          content: '\f01a';
        }
      }
    }

    .faq_question {
      border-bottom: 1px solid rgba(0, 0, 0, .2);
      padding: 10px 0;

      &::before {
        content: '\f18e';
        font-size: 20px;
        display: inline;
        margin-right: 15px;
        font-family: FontAwesome, sans-serif;
      }
    }

    .faq_answer {
      overflow: hidden;
      display: block;
      margin-left: 30px;
    }
  }
}

.social-networks.template_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  > div {
    margin-right: 10px;
  }

  .pinterest {
    padding-bottom: 5px;
  }

  .instagram {
    flex: 0 0 auto;

    a {
      display: inline-block;
      margin: 0;
    }

    img {
      display: inline-block;
      vertical-align: top;
      margin: 0;
      border: none;
    }
  }
}

.social-networks.template_2 {
  display: block;

  > div {
    width: 100%;
    text-align: center;
    display: block;
  }

  .pinterest {
    padding-bottom: 5px;
  }
}


.banner_diaporama {

  .slider-see-more {
    float: right;
    margin: 10px 10px 0 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.slider-iview {
  .iviewSlider {
    position: relative;
  }

  .slider-see-more {
    position: absolute;
    bottom: 30%;
    right: 20px;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.fluxSlider {
  .fluxslider {
    margin: auto;
  }

  .fluxSlider-item {
    text-align: center;
    display: none;

    .slider-see-more {
      display: none;
    }
  }

  .fluxSlider-item:first-child {
    display: block;
  }
}

/* Bloc des cookies */
#bloc_cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  box-sizing: border-box;
  background: #fff !important;
  box-shadow: 0 0 5px #000;
  display: none;
  font-size: 0;
  width: 100% !important;
  z-index: 99999;

  &::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }

  p {
    width: 65%;
    margin: 10px 0 10px 5% !important;
    text-align: left;
    display: inline-block;
    vertical-align: middle;
    font-size: 1rem;
    float: none !important;
    overflow: visible;
    color: #000 !important;
  }

  .bloc_boutons_cookies {
    display: inline-block;
    vertical-align: middle;
    font-size: 1rem;
    float: none !important;
    overflow: visible;
    width: 25%;
    margin: 10px 5% 10px 0 !important;
    text-align: center;
  }

  .button {
    display: inline-block !important;
    margin: 5px !important;
    padding: 5px 10px !important;

    &:hover {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 680px) {
  .social-networks.template_1 {
    display: block;

    > div {
      margin-right: 10px;
      display: block;
      text-align: center;
    }

    .pinterest {
      padding-bottom: 5px;
    }
  }
  #bloc_cookies {
    p, .bloc_boutons_cookies {
      width: 90% !important;
    }

    p {
      margin: 0 5% !important;
      font-size: 14px !important;
    }

    .bloc_boutons_cookies {
      margin: 10px 5% 0 !important;
    }
  }

}

.nav-static-breadcrumb li {
  float: left;
  list-style-type: none;
}

.nav-static-breadcrumb li:not(:last-child)::after {
  margin: 0 5px;
  content: '';
  display: inline-block;
}

#nav-principal::before, #nav-principal::after {
  content: '';
  display: block;
  clear: both;
}

.form-contact-blocked {
  transform: translateZ(0);
  backface-visibility: hidden;

  .form-contact-block-message {
    background: rgba(255, 255, 255, 0.701961);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9989;
    text-align: center;
    overflow: hidden;

    .form-contact-block-message-content {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      backface-visibility: hidden;
      margin-top: -49px;
      font-size: 22px;

      > div:first-child {
        color: #000;
      }
    }
  }
}

/*Menu responsive */
.dropDown::before {
  content: '\f107';
  font-family: fontAwesome, sans-serif;
  width: 100%;
  text-align: center;
}

.cmonsite-toolbar {
  position: fixed;
  z-index: 9999;
  background: #fff;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 0 4px #ccc;
  padding: 5px;

  &--top-left {
    top: 0;
    left: 20px;
  }

  &--top-right {
    top: 0;
    right: 20px;
  }

  &--bottom-left {
    bottom: 0;
    left: 20px;
  }

  &--bottom-right {
    bottom: 0;
    left: 20px;
  }

  &--middle-left {
    top: 45%;
    left: 0;
  }

  &--middle-right {
    top: 45%;
    left: 0;
  }

  display: flex;
  align-items: center;
}

@media #{$medium-and-down} {

  .has-cmonsite-toolbar--top {
    padding-top: 40px;
  }

  .cmonsite-toolbar {
    box-shadow: none;
    border: none;
    position: absolute;
  }

}

.choice-currency {
  box-sizing: border-box;

  .inner-choice-currency {
    box-sizing: border-box;
    padding: 5px;
  }

  select {
    padding: 2px 10px;

    &:focus {
      box-shadow: none;
    }
  }
}

@media #{$medium-and-down} {

  .choice-currency {
    .inner-choice-currency {
      padding: 0;
    }

    select {
      height: 30px;
      margin: 0;
    }
  }

}

.modif-design .cmonsite-toolbar {
  display: none;
}

#cmonsite_translate_element {

  ~ .choice-currency .inner-choice-currency {
    padding: 0 5px 5px 10px;
    border-left: 1px solid;
  }
}

#cmonsite_translate_element {
  position: static;
  z-index: 9999;

  #block-google-trad {
    padding: 0 5px;
  }

  .goog-te-gadget-simple {
    padding: 8px 0 9px;
  }

  &.cmonsite_translate_element_top-left {
    top: 20px;
    left: 0;

    .goog-te-gadget {
      border-radius: 0 10px 10px 0;
    }
  }

  &.cmonsite_translate_element_middle-left {
    top: 45%;
    left: 0;

    .goog-te-gadget {
      border-radius: 0 10px 10px 0;
    }
  }

  &.cmonsite_translate_element_top-right {
    top: 20px;
    right: 0;

    .goog-te-gadget {
      border-radius: 10px 0 0 10px;
    }
  }

  &.cmonsite_translate_element_middle-right {
    top: 45%;
    right: 0;

    .goog-te-gadget {
      border-radius: 10px 0 0 10px;
    }
  }

  .goog-te-menu-value span:first-child {
    display: none;
  }

  .goog-te-gadget-icon {
    background-position: center center !important;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.cmonsite_translate_element_fr {
    /* Modification de la boite de traduction de google */
    .goog-te-gadget-icon {
      background-image: url('/images/flag/flag-32/fr.png') !important;
    }
  }

  &.cmonsite_translate_element_en {
    /* Modification de la boite de traduction de google */
    .goog-te-gadget-icon {
      background-image: url('/images/flag/flag-32/en.png') !important;
    }
  }

  &.cmonsite_translate_element_es {
    /* Modification de la boite de traduction de google */
    .goog-te-gadget-icon {
      background-image: url('/images/flag/flag-32/es.png') !important;
    }
  }

  &.cmonsite_translate_element_small {
    .goog-te-menu-value {
      font-size: 12px;
    }

    .goog-te-gadget-icon {
      width: 16px;
    }

  }

  .cmonsite_translate_element_medium {
    .goog-te-menu-value {
      font-size: 16px;
    }

    .goog-te-gadget-icon {
      width: 24px;
    }

  }

  &.cmonsite_translate_element_big {
    .goog-te-menu-value {
      font-size: 20px;
    }

    .goog-te-gadget-icon {
      width: 32px;
    }

  }
}

#cmonsite_translate_element span, #cmonsite_translate_element .goog-te-gadget-simple {
  border: transparent !important;
  background: transparent;
}

.translated-ltr #cmonsite_translate_element,
.cmonsite-editor-start #cmonsite_translate_element {
  display: none;
}

#cmonsite_translate_element .goog-te-menu-value {
  margin-left: -10px !important;
}

@media #{$medium-and-down} {
  #cmonsite_translate_element .goog-te-gadget-simple {
    padding: 5px 0 5px;
  }
}

.block-fb-login {
  margin-top: 21px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  display: none;

  .fb-login-button {
    display: inline-block;
    background: linear-gradient(#4c69ba, #3b55a0);
    border-color: #4c69ba;
    border-radius: 2px;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    text-shadow: 0 -1px 0 #354c8c;
    padding: 5px 12px;

    i {
      font-size: 19px;
      margin-right: 6px;
    }

    &:hover {
      background: linear-gradient(#5b7bd5, #4864b1);
      border-bottom-color: #365899;
      border-left-color: #4961a8;
      border-right-color: #4961a8;
      border-top-color: #5874c3;
      box-shadow: inset 0 1px 0 #607fd6;
      cursor: pointer;
    }
  }
}

.block_address {
  .list_address {
    li {
      list-style: none;
    }

    .change_address {
      cursor: pointer;
    }
  }
}

.order_product {
  width: 100%;

  .filter-products {
    text-align: right;

    &::after {
      content: '';
      display: table;
      clear: both;
    }

    .filter-products-label {
      display: inline-block;
      margin: 0 5px 0 0;
      font-weight: 200;
      text-align: right;
      height: 34px;
      vertical-align: middle;
      line-height: 32px;
    }

    .filter-products-select {
      max-width: 220px;
      display: inline-block;
      width: 100%;
    }
  }

  .form-group {
    label {

    }
  }

  select {
    width: 100%;
    left: 0;
    height: 29px;
    margin-top: 0;
  }
}

form a.button {
  text-decoration: none;
}

.law-computer-and-freedoms {
  .help-block {
    display: block;
    margin-top: 5px;
    color: #737373;
    font-size: 13px;
    font-style: italic;
  }
}

.badge-order-step {
  display: inline;
  padding: 0.3em 0.4em;
  line-height: 1;

  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  font-size: 14px;

  &__1 {
    color: #000;
    background: #efefef;
  }

  &__2 {
    color: #000;
    background: #dbe4ff;
  }

  &__10 {
    background: #c2255c;
    color: #fff;
  }

  &__50 {
    background: #ff0000;
    color: #fff;
  }

  &__51 {
    background: #ff0000;
    color: #fff;
  }

  &__99 {
    background: #038404;
    color: #fff;
  }

  &__100 {
    background: #37b24d;
    color: #fff;
  }

  &__149 {
    background: #ffa94d;
    color: #fff;
  }

  &__150 {
    background: #f76707;
    color: #fff;
  }

  &__151 {
    background: #ffa94d;
    color: #fff;
  }

  &__152 {
    background: #ffa94d;
    color: #fff;
  }

  &__153 {
    background: #f76707;
    color: #fff;
  }

  &__200 {
    background: #5c940d;
    color: #fff;
  }

  &__201 {
    background: #33C0DE;
    color: #fff;
  }

  &__210 {
    background-color: #8f0621;
    color: white;
  }

}

.wrap-description, .ficheprod-description {

  .reservation__addbasket {
    .bloc-quantite {
      label {
        margin-right: 8px;
      }

      input {
        width: 50px;
      }
    }
  }

  .bloc-quantite {

    display: inline-flex;
    margin-right: 15px;
    align-items: center;
    flex-wrap: wrap;

    label {
      line-height: 30px;
      margin: 0;
      font-size: 18px;
      font-weight: 700;
      font-family: 'Dosis', sans-serif;
    }

    input {
      margin: 0;
      text-align: center;
    }

    .bloc-bt-quantity {

      margin-left: 8px;
      margin-bottom: 5px;
      display: flex;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -o-user-select: none;
      user-select: none;
      height: 40px;

      .bt-quantity {
        width: auto;
        height: 100%;
        font-size: 16px;
        color: #666;
        margin: 0;
        border-width: 1px;
        border-style: solid;
        border-color: #e1e1e1;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: line-height .1s linear;
        background-color: white;

        &:hover {
          color: #000;
        }

        &::before {
          content: '';
          display: block;
          text-align: center;
          font-family: 'fontAwesome', sans-serif;
        }

        &.add-quantity {
          border-left-width: 0;

          &::before {
            content: "\f067";
          }
        }

        &.less-quantity {
          border-right-width: 0;

          &::before {
            content: "\f068";
          }

        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .content #fiche-produit {
    .wrap-description, .ficheprod-description {
      .ajouter-panier .bloc-quantite .quantite {
        margin-right: 0;
      }
    }
  }
}


@media screen and (max-width: 575px) {
  .google-recaptcha {
    transform: scale(0.77);
    transform-origin: 0;
    transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform: scale(0.77);
    transform: scale(0.77);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }
}