/* 
    Created on : 27 janv. 2015, 15:00:58
    Author     : Damien T. - Lord of Web
*/

.side-tabs {
    position: relative;
    z-index:1;
    a, &.etapes li:nth-child(3) span {
        display: block;
        color: rgb(68, 68, 68);
        font-size: 14px;
        font-weight: bold;
        line-height: 28px;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        margin-bottom: -1px;
        padding: 3px 0px 3px 15px;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        outline: none;
        text-decoration: none;
        &.active {
            border-left: 4px solid #91B911;
            border-right: 1px solid #fff;
            padding-left: 12px;
        }
        &:hover, &:focus, &:active {
            text-decoration: none;
            color: #444;
            outline: none;

        }

        .fa {
            margin-right: 10px;
        }
    }

    &.top { 
        a, li:nth-child(3) span {
            float: left;
            padding: 3px 15px 3px 15px;
            margin-right: -1px;
            &.active {
                border-left: 1px solid #ddd;
                border-right: 1px solid #ddd; 
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                position: relative;
                &:after {
                    position: absolute;
                    bottom: 5px;
                    left: 0;
                    width: 100%;
                    height: 4px;
                    background: #91B911;
                    content: '';
                    -webkit-transition: -webkit-transform 0.3s;
                    transition: transform 0.3s;
                    -webkit-transform: translate3d(0,150%,0);
                    transform: translate3d(0,150%,0);
                }
            }
        }
        /* CLEARFIX */
        *zoom:1;
        &:before, &:after{
            display:table;
            content:"";
            line-height:0 
        }
        &:after{
            clear:both
        }
    }
    &.etapes{
        width: 100%;
        padding-left: 0px;
        
        ul{
            display: block;
            padding-left: 0;
            margin-left: 0 !important;
        }
        li{
            position: relative;
            float: left;
            list-style: none;
            background-color: #e5e5e5;
            width: 33.33%;
            margin: 0;
            color: #222;
            border: none;
            border-radius: 0;
        }
        a::before, li:nth-child(3) span::before{
            top: 0;
            content: "";
            border-style: solid;
            border-color: transparent transparent transparent #fff;
            left: 0px;
            border-width: 17px 0 17px 16px;
            position: absolute;
        }
        li:nth-child(3) a{
            display: none;
        }
        > ul > li:first-child a::before{
            border: none;
        }
        &.top a, &.top li:nth-child(3) span{
            float: none;
            display: inline-block;
            border: none;
            padding: 3px 15px 3px 23px;
            position: static;
            color: #444;
        }
        .etapes-after{
            border-width: 17px 0 17px 11px;
            border-color: transparent transparent transparent #e5e5e5;
            z-index: 15;
            right: -11px;
            position: absolute;
            top: 0;
            width: 0;
            height: 0;
            border-style: solid;
        }
    }

}

.contents-tabs {
    position: relative;
    padding-top: 10px;
    &:before {
        border-left: 1px solid #ddd;
        content: "";
        height: 100%;
        left: -3.564%;
        position: absolute;
    }
    &[class^="col-"]:before,
    &[class*=" col-"]:before{
        left: -16px;
    }
    &.top {
        border-top: 1px solid #ddd;
        /* CLEARFIX */
        *zoom:1;
        &:before, &:after{
            display:table;
            content:"";
            line-height:0
        }
        &:after{
            clear:both
        }
    }
}

.content-tab {
    padding-top: 10px;
    display: none;
    &.active {
        display: block;
    }
}

.no-js .content-tab {
    display: block;
}

