.instagram-feed.instagram-feed-4 {
  .instagram-feed__item {
    width: 100%;
  }


  .swiper-button-next, .swiper-button-prev {
    color: rgb(0, 0, 0);
  }

  .swiper-pagination-bullets {
    color: rgb(0, 0, 0);
  }

  .swiper-pagination-bullet-active {
    background: currentColor;
  }
}
