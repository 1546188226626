.content_list_product {
  #infscr-loading {
    text-align: center;
  }
}

.content_pagination_product {

}

.content_pagination_product {
  text-align: center;

  .pagination {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    li {
      width: auto;
    }

    a {
      margin: 0;
      border-radius: 0;


      &.button {
        margin: 0 0 0 -1px;
        width: auto;
        min-width: auto;

      }
    }

    a, span {
      font-size: 18px;
    }
  }
}

.faceting {
  display: flex;
  flex-wrap: wrap;
  user-select: none;

  > * {
    flex: 1 0 auto;
  }

  &__title {
    text-align: center;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .faceting__list {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }

  &__item {
    position: relative;
  }

  &__label {
    display: block;
    padding: 10px;
    cursor: pointer;
  }

  &__list {

    > li {
      list-style-type: none;
    }
  }

  &__input {
    // visually Hidden
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
  }

  &__value-nb {
    float: right;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    font-size: 0.8em;
    border-radius: 0.6em;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-align: center;
    display: inline-block;
    flex: 0 0 auto;
    margin-top: 0.3em;
    padding: 0 0.5em;
  }

  &__submit {
    display: none;

    button {
      cursor: pointer;
    }
  }
}

.no-js .faceting__submit {
  display: block;
}

.faceting__value-label {
  flex: 1 1 auto;
  line-height: 1.2em;
  display: inline-block;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

.faceting__value {
  display: flex;
  align-items: flex-start;
  position: relative;
  line-height: 1.6;
  cursor: pointer;
  padding: 0 0.6em;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:before {
    content: '';
    border: 1px solid;
    border-radius: 2px;
    height: 1em;
    width: 1em;
    display: inline-block;
    vertical-align: middle;
    flex: 0 0 auto;
    margin-right: 0.5em;
    margin-top: 0.3em;
  }

  &:after {
    content: none;
    display: inline-block;
    position: absolute;
    left: 0.65em;
    top: 0.3em;
    font-size: 1.2em;
    line-height: 0.8em;
    font-weight: bold;
    flex: 0 0 auto;
  }
}

.faceting__input:checked + .faceting__value {
  &:after {
    content: "\2713";
  }
}

.content {
  .faceting {
    display: flex;
    flex-wrap: wrap;
  }

  .faceting__title {
    text-align: left;
    display: none;
  }

  .faceting__list {
    display: none;

    border: 1px solid;
    margin-top: -1px;
    max-height: 250px;
    overflow: auto;
    position: absolute;
    min-width: 100%;
    z-index: 200;
    background: #fff;
    padding-top: 0.5em;
    padding-bottom: 0.5em;

  }

  .faceting__value-label {
    white-space: nowrap;
    padding-right: 0.5em;
  }

  .faceting__item {
    max-width: 25%;
    margin-bottom: 1em;
  }

  .faceting__item {
    margin-right: -1px;
  }

  .faceting__label {
    border: 1px solid;
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;

    &:after {
      @include char(f078);
      font-family: FontAwesome;
      display: block;
      //position: absolute;
      line-height: 1em;
      font-size: 0.6em;
      margin-left: auto;
      //top: 50%;
      //right: 0.5em;
      //transform: translateY(-50%);
    }
  }

  .faceting__item--open {
    .faceting__list {
      display: block;
    }

    .faceting__label {
      &:after {
        @include char(f077);
      }
    }

  }

}

// dans la sidebar
.sidebar {

  .faceting {
    display: block;
    padding: 0 10px;
    margin-bottom: 20px;
  }

  .faceting__item {
    width: auto;
  }

  .faceting__label {
    padding: 5px 0 0;
    border-bottom: 1px solid;
    margin-bottom: 5px;
  }

  .faceting__list {
    padding: 2px 5px;
  }

  .faceting__submit {
    text-align: right;
  }

}


@media #{$small-only} {
  .content {
    .faceting__item {
      max-width: 100%;
      width: 100%;
    }
  }
}

@media #{$medium-and-down} {
  .content {
    .faceting__item {
      max-width: 33.33%;
    }
  }

}

@media #{$small-and-down} {
  .content {
    .faceting__item {
      max-width: 50%;
    }
  }

}