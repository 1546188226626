.produits{
  .productImageWrap{
    position: relative;
  }
}
.img-square {
  position: relative;
  padding-bottom: 100%;
  display: block;
}

.img-square__content {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

}

.img-square__content img {
  object-fit: contain;
}

.img-square.produits-bloc-new-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}