.widget.widget-grid {
  position: relative;
  padding: 0;

  .row-grid {
    position: relative;
    z-index: 2;
  }

  .full-background {
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    height: 100%;
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
  }

}

.widget-grid-full-width {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}

.no-gutter {
  padding-left: 0;
  padding-right: 0;

  > .row {
    margin-left: 0;
    margin-right: 0;

    > .col {
      padding-left: 0;
      padding-right: 0;
    }
  }


}

.gutter {
  .row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .col {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
