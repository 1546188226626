#modal-add-to-cart-success {
  color: #000;
  text-align: center;
  .buttons__panier {
    display: flex;
    flex-direction: column;
    align-items: center;
    > * {
      flex: 0 0 auto;
      margin-bottom: 10px;
    }

  }

  .button {
    cursor: pointer;
  }
}